@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Inter var', ui-sans-serif, system-ui, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

body {
  background: #ffffff;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

html {
  scroll-behavior: smooth;
}

/* Hide scrollbar for WebKit browsers */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

@keyframes slide-in-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in-from-bottom {
  animation: slide-in-from-bottom 0.5s ease-out;
}

/* Add this to your CSS file */

.loader {
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  animation: spin 1s linear infinite;
}


/* Loading Spinner */
.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3b82f6;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Separator */
.separator {
  background: linear-gradient(90deg, transparent, #ffffff, transparent);
}

/* Layout Stability */
[class*="Section"] {
  min-height: 500px;
  /* Adjust based on your content */
}


/* styles.css */

.bg-light-grid {
  background-image:
    linear-gradient(to right, #e8e8e8d6 1px, transparent 1px),
    linear-gradient(to bottom, #f3f3f39d 1px, transparent 1px),
    radial-gradient(closest-side, rgba(255, 255, 255, 0.8), transparent);
  background-size: 60px 60px;
  /* Grid size */
  background-blend-mode: soft-light, normal;
  /* Blend grid lines and fade effect */
}

@keyframes earth-spin {
  0% {
    transform: rotateY(0deg) rotateX(23.5deg);
  }

  100% {
    transform: rotateY(360deg) rotateX(23.5deg);
  }
}

.earth-spin {
  animation: earth-spin 5s linear infinite;
  /* Adjust duration as needed */
  transform-origin: center;
}

@keyframes moveDiagonal {
  0% {
    transform: translate(0, 0) rotate(45deg);
  }

  50% {
    transform: translate(10px, 10px) rotate(45deg);
  }

  100% {
    transform: translate(0, 0) rotate(45deg);
  }
}


/* In your CSS file (e.g., index.css) */
.sticky-steps {
  z-index: 10; /* Ensure steps stay above other content */
}