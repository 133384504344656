/* BlogContent.css */

/* Overall blog content styling */
.blog-content {
    font-family: 'Inter', sans-serif;
    line-height: 1.8;
    color: #333;
    margin-bottom: 2rem;
  }
  
  /* Blog title styling */
  .blog-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1a202c; /* Tailwind's text-gray-900 */
    margin-bottom: 1rem;
  }
  
  /* Blog context (e.g. description) styling */
  .blog-context {
    font-size: 1.125rem; /* Tailwind's text-lg */
    line-height: 2rem;   /* Tailwind's leading-8 */
    color: #4a5568;      /* Tailwind's text-gray-700 */
    margin-bottom: 1.25rem;
  }
  
  /* Meta information styling */
  .blog-meta {
    font-size: 0.875rem;
    color: #718096;      /* Tailwind's text-gray-500 */
    margin-bottom: 1.5rem;
  }
  
  /* Headings within content */
  .blog-content h1,
  .blog-content h2,
  .blog-content h3,
  .blog-content h4,
  .blog-content h5,
  .blog-content h6 {
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    line-height: 1.2;
  }
  
  .blog-content h1 { font-size: 2.5rem; }
  .blog-content h2 { font-size: 2rem; }
  .blog-content h3 { font-size: 1.75rem; }
  .blog-content h4 { font-size: 1.5rem; }
  .blog-content h5 { font-size: 1.25rem; }
  .blog-content h6 { font-size: 1rem; }
  
  /* Paragraphs */
  .blog-content p {
    margin-bottom: 1.25rem;
    font-size: 1rem;
  }
  
  /* Lists */
  .blog-content ul,
  .blog-content ol {
    margin-left: 1.5rem;
    margin-bottom: 1.25rem;
    padding-left: 1rem;
  }
  
  .blog-content ul {
    list-style-type: disc;
    list-style-position: outside;
  }
  
  .blog-content ol {
    list-style-type: decimal;
    list-style-position: outside;
  }
  
  .blog-content li {
    margin-bottom: 0.5rem;
  }
  
  /* Blockquotes */
  .blog-content blockquote {
    margin: 1.5rem 0;
    padding-left: 1.5rem;
    border-left: 4px solid #e2e8f0;
    font-style: italic;
    color: #4a5568;
  }
  
  /* Images */
  .blog-content img {
    max-width: 100%;
    height: auto;
    border-radius: 0.5rem;
    margin: 1rem 0;
  }
  
  /* Code blocks */
  .blog-content pre {
    background-color: #f7fafc;
    padding: 1rem;
    border-radius: 0.5rem;
    overflow-x: auto;
    margin-bottom: 1.25rem;
    font-size: 0.875rem;
  }
  
  /* Inline code */
  .blog-content code {
    background-color: #edf2f7;
    padding: 0.2rem 0.4rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
  }
  
  /* Links */
  .blog-content a {
    color: #5a67d8;
    text-decoration: underline;
    transition: color 0.2s ease;
  }
  
  .blog-content a:hover {
    color: #434190;
  }
  
  /* Badge for time (if needed) */
  .time-badge {
    display: inline-block;
    background-color: #E0E7FF; /* Light indigo */
    color: #3730A3;           /* Darker indigo text */
    border-radius: 9999px;    /* Pill shape */
    padding: 0.25rem 0.75rem;  /* Spacing around text */
    font-size: 0.875rem;      /* Similar to text-sm */
    font-weight: 500;
    line-height: 1.25;
  }
  